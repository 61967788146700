import { objectArray } from '@/helpers/jsonApiTransformer'
export const generalServiceFormMixin = {
  data() {
    return {

      // Enums
      // Catalogos
      stateCatalog: [],
      serviceTypesCatalog: [],
      transactionTypesCatalog: [],
      clientsCatalog: [],
      serviceEngineersCatalog: [],
    }
  },
  methods: {
    getStatesCatalog() {
      this.$store.dispatch('jv/get', ['states', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.stateCatalog = objectArray(tableData)
        })
    },
    getServiceTypesCatalog() {
      this.$store.dispatch('jv/get', ['serviceTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.serviceTypesCatalog = objectArray(tableData)
        })
    },
    getTransactionTypesCatalog() {
      this.$store.dispatch('jv/get', ['transactionTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.transactionTypesCatalog = objectArray(tableData)
        })
    },
    getClientsCatalog() {
      this.$store.dispatch('jv/get', ['clients', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.clientsCatalog = objectArray(tableData)
        })
    },
    getServiceEngineersCatalog() {
      // ToDo Agregar filtro para consultar solo ings de servicio
      this.$store.dispatch('jv/get', ['users', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.serviceEngineersCatalog = objectArray(tableData)
        })
    },
  },
}
